.change-note-modal__container {
    width: 579px;
    height: 667px;
    background: #1D1D1F;
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
}

.change-note-modal__container .change-note-modal__header {
    height: 76.06px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #25272B;
    border-radius: 5px 5px 0 0;
    padding: 0 2.0625em 0 2.0625em;
}

.change-note-modal__container .change-note-modal__header>span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}

.change-note-modal__container .close-icon {
    font-size: 18px;
    color: #737880;
    font-weight: 100 !important;
    cursor: pointer;
}

.change-note-modal__container .changes-notes {
    width: 99%;
    height: 567.9px;
    overflow-y: scroll;
    scrollbar-color: red;
    scrollbar-width: thin;
    padding: 1.975em 0 0 2.125em;
    box-sizing: border-box;
}

.change-note-modal__container .changes-notes::-webkit-scrollbar {
    width: 10px;
    background: none;
}

.change-note-modal__container .changes-notes::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    height: 30px;
}

.change-note-modal__container .note-li {
    display: flex;
    flex-direction: column;
    margin-bottom: 30.3px;
}

.change-note-modal__container .note-li>span:nth-child(1) {
    line-height: 15px;
    margin-bottom: 8.87px;
}

.change-note-modal__container .note-li>span:nth-child(2) {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px !important;
    margin: 3px 0;
}

.change-note-modal__container button.btn-custom.btn-tertiary.for-note {
    text-align: unset;
    align-self: unset;
}

@media screen and (max-width: 700px) {
    .change-note-modal__container {
        width: 98%;
        height: 80vh
    }
}