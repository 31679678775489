.notes-collapse {
    flex: 3;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    margin: 18px 0;
}

.notes-collapse>span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px !important;
    height: fit-content;
    color: #89969F;
    margin-bottom: 6px;
}

.notes-collapse>span button {
    height: fit-content !important;
}

.notes-collapse span.question-details {
    font-weight: bold;
}

button.btn-custom.btn-tertiary.for-note {
    width: 168px;
}