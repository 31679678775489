.table-grid-body{
    display: grid;
    grid-template-columns: 10% repeat(4, 1fr);
    border-left: 3px solid transparent;
    cursor: pointer;
    border-top: 1px solid #2B2D31;
}

.table-grid-body > .header {
    display: none;
}

.table-grid, .table-grid-body{
    display: grid;
    grid-template-columns: 10% repeat(4, 1fr);
    border-bottom: 1px solid #2B2D31;
}

.cohort-grid, .cohort-table-grid-body {
    grid-template-columns: 10% repeat(5, 1fr);
}

.course-name {
    justify-content: start;
    padding-left: 40px !important;
}

.table-grid-body > span {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    color: #FFFFFF;
    padding: 20px 0;
    position: relative;
}

.table-grid-body span:nth-child(2) {
    font-weight: normal;
}

.table-grid-body:hover {
    background: #1D1D1F;
    border-bottom: none !important;
}

.details-tr-expanded {
    overflow: hidden;
    width: 100%;
    border-left: 3px solid #77B1AF;
    animation: opacityOn 1s;
    border-bottom: 1px solid #2B2D31;
    padding: 20px 0 20px 10%;
    grid-column-start: 1;
    grid-column-end: 7;
}

.expand-btn, .expand-btn-expanded{
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #77B1AF;
    box-sizing: border-box;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.expand-btn::before, .expand-btn-expanded::before {
    content: '';
    position: absolute;
    width: 12.86px;
    height: 0px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    border: 1px solid #77B1AF;
}

.expand-btn::after {
    content: '';
    position: absolute;
    height: 12.86px;
    width: 0px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    border: 1px solid #77B1AF;
    transition: all .2s ease-out;
}

.expand-btn-expanded::after {
    content: '';
    position: absolute;
    height: 12.86px;
    width: 0px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%) rotate(90deg);
    border: 1px solid #77B1AF;
    transition: all .2s ease-in;
}

@keyframes opacityOn {
    0% {
        opacity: 0;
        padding: 20px 0 20px 10%;
        max-height: 0px;
    }
    100% {
        opacity: 1;
        padding: 20px 0 20px 10%;
        max-height: 500px;
    }
}

@media screen and (max-width: 700px) {
    .cohort-text {
        justify-content: start;
    }

    .course-name {
        padding-left: 0px !important;
    }

    .table-grid {
        display: none;
    }
    .table-grid-body {
        display: grid;
        grid-template-columns: 60% 40%;
        border-bottom: 1px solid #2B2D31;
        padding-bottom: 1em;
    }

    .table-grid-body > span {
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        color: #FFFFFF;
        padding: 20px 0;
        position: relative;
    }

    .table-grid-body span:nth-child(1){
        order: 0;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
    }

    .table-grid-body span:nth-child(2) {
        order: -1;
        margin-left: 2.6em;
        display: flex;
    }

    .section-container {
        display: inline!important;
        line-height: 24px!important;
        height: auto!important;
        padding: 0!important;
        margin-top: 1em;
    }

    .button-container {
        line-height: 24px!important;
        align-content: center;
        margin-top: .5em;
    }

    .expand-btn, .expand-btn-expanded{
        position: relative;
        width: 24px;
        height: 24px;
        border: 2px solid #77B1AF;
        box-sizing: border-box;
        border-radius: 5px;
        top: 0;
        left: 0;
        transform: translateY(-12px);
        cursor: pointer;
    }

    .table-grid-body > .header {
        padding-left: 3em;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #89969F !important;
    }

    .table-grid-body > span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        color: #FFFFFF;
        position: relative;
        height: .1em;
    }
}



