.edit-grade-wrapper {
    padding: 36px;
    width: 514px;
}

.edit-grade-wrapper span:first-child {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: #FFFFFF;
}

.section-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 24px;
    color: #89969F;
}

.sectionError {
    margin-bottom: 6px;
}

.correct div {
    display : inline-block;
    color: #77B1AF !important;
}

.questionStatus {
    margin-bottom: 12px;
    line-height: 19px;
}

.questionStatus div {
    display : inline;
    font-weight: bold;
    color: #E1774F;
}

.container {
    width: fit-content;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    line-height: 19px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 3px solid #89969F;
    box-sizing: border-box;
    border-radius: 16px;
}

.container:hover input ~ .checkmark {
    background-color: #89969F;
}

.container input:checked ~ .checkmark {
    background-color: #77B1AF;
    border: 3px solid #77B1AF;
}

.form__modal {
    line-height: 19px;
    margin-bottom: 24px;
}

.form-group__modal {
    width: 100%;
}

.form div {
    width: 100%;
}

.text-area {
    width: 100%;
    background: #1d1d1f;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 6px;
    resize: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.text-area:focus {
    border-color: #5FC4B8;
}

.text-area-error {
    border: 1px solid #E1774F;
    width: 100%;
    background: #1d1d1f;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 16px;
    resize: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.text-area::placeholder {

}

.buttons {
    display: flex;
    margin: 0 36px 36px 24px;
    justify-content: flex-start;
}

.buttons .button {
    margin-left: 12px;
}

.buttons .button {
    margin-left: 12px;
}

.text-error {
    font-size: 18px;
    line-height: 22px;
    color: #E1774F;
    margin-bottom: 12px;
}

