.editable-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.edit-back {
    display: flex;
    width: 20em;
    padding-top: 1em;
    padding-left: 2em;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #77B1AF;
}

svg.svg-inline--fa.fa-angle-left.fa-w-8 {
    margin-top: 2px;
}

.edit-back p {
    margin-left: 10px;
    font-family: Lato;
}

.edit-student-info {
    width: 100%;
    text-align: center;
    line-height: 22px;
    padding: 2em;
}

.edit-student-info p.student-name-back {
    font-style: normal;
    font-size: 14px;
    color: #77B1AF;
    letter-spacing: 1px;
    padding: 0;
    font-weight: bold !important;
}

.edit-student-info p.student-name-title {
    font-style: normal;
    font-size: 18px;
    color: #89969F;
    letter-spacing: 1px;
}

.edit-student-info p:nth-child(2) {
    font-size: 18px;
    letter-spacing: 1px;
    font-style: normal;
}

.edit-student-info p:nth-child(3) {
    font-size: 22px !important;
    line-height: 26px;
}

.grades-list-container {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

div.li-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: inherit;
}

div.li-list p {
    font-size: 18px;
    padding: .6em 0 0 0;
}

div.li-list p:nth-child(1) {
    flex: .2;
    text-align: center;
    text-align: left;
    line-height: 22px;
    letter-spacing: 1px;
}


div.li-list p:nth-child(2) {
    flex: 2;
    line-height: 22px;
    padding-left: .5em;
    font-weight: bold !important;
    letter-spacing: 1px;
}

.li-list {
    border-top: 2px solid #202124;
}

div.grades-list-container .li-list-wrapper:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.changes-list {
    margin-bottom: 24px;
}

.changes-item {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #89969F;
}

.changes-item:nth-child(2) span:nth-child(2) {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #77B1AF;
    text-decoration: underline;
}

.changes-item span{
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #89969F;
    margin-right: 10px;
}

.changes-item a{
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #77B1AF;
    text-decoration: underline;
}

.grader-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
}

.score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 3em;
}

.score-container p {
    line-height: 25px;
    font-family: Lato;
}

.score-container p:nth-child(1) {
    font-size: 18px;
    line-height: 22px;
}

.score-container p:nth-child(2) {
    font-size: 22px;
    line-height: 26px;
}

.btn-wrapper {
    display: flex;
    justify-content: center !important;
    min-width: 318px;
    gap: 12px;
    margin-top: 10px;
}

.btn-custom.btn-primary.commit-btn {
    font-size: 13px;
    line-height: 16px;
}

.confirmation-modal-wrapper {
    font-size: 18px;
    line-height: 26px;
    width: 450px;
    min-height: 203px;
    padding: 23px 47px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.changes-row {
    display: flex;
    justify-content: space-between;
}

.remove-btn {
    height: 23px !important;
}


@media screen and (max-width: 700px) {
    .edit-student-info p:nth-child(2) {
        font-size: 16px;
    }
    .edit-student-info p:nth-child(3) {
        font-size: 20px;
    }
}
