.pagination {
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
}

.pagination li {
  margin: 0 3px;
}

.pagination li a {
  color: #fff;
  cursor: pointer;
}

.pagination li a:hover {
  text-decoration: none;
}

.pagination li a:focus {
  outline: transparent;
}

.pagination .previous,
.pagination .next {
  border-width: 0.5px;
}

.pagination .previous {
  margin-right: 3px;
  border-right-style: solid;
  padding-right: 8px;
}

.pagination .next {
  margin-left: 3px;
  border-left-style: solid;
  padding-left: 8px;
}

.pagination .previous.disabled,
.pagination .next.disabled {
  display: none;
}

.pagination .active {
  text-decoration: underline;
}
