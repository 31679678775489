.table-responsive{
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: auto;
}

.edit-and-delete-table {
  display: table;
  border-collapse: separate;
  border-spacing: unset;
  }

.edit-and-delete-table thead tr th {
  white-space: nowrap;
}

.edit-and-delete-table tbody div p.noResults {
  margin-left: 5px;
}

.edit-and-delete-table td.action-wrapper,
.edit-and-delete-table th.action-wrapper,
.edit-and-delete-table td.action-wrapper-body-cell {
  padding: 5px;
  border: 2px solid #fff;
  font-size: 14px;
}

.edit-and-delete-table td.action-wrapper-body-cell {
  font-size: 20px;
}

.edit-and-delete-table td .action-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-and-delete-table .edit {
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 5px;
}

.edit-and-delete-table .delete {
  color: rgb(224, 137, 137);
  cursor: pointer;
}

.tableHeader {
  border: 1px solid #5B6067;
  padding: 8px 80px 8px 5px;
  font-size: 14px;
  position: sticky;
  background-color: var(--content-background-color);
  top: 0;
  cursor: pointer;
  vertical-align: inherit;
  text-align: left;
  z-index: 5;
}

.table thead tr td {
  background: var(--content-background-color);
  position: sticky;
  top: 0;
}

.table tbody tr td:nth-child(1) {
  background: var(--content-background-color);
  left: 0;
  min-width: 45px;
  position: sticky;
  word-break: break-all;
  text-align: center;
  cursor: pointer;
}

.table tbody tr td:nth-child(2) {
  background: var(--content-background-color);
  left: 45px;
  min-width: 300px;
  position: sticky;
  word-break: break-all;
}

.table tbody tr td:nth-child(3) {
  background: var(--content-background-color);
  left: 345px;
  position: sticky;
}


.headerColumn {
  border: 0.5px solid #5B6067;
  padding: 12px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.tableBody {
  border: 1px solid #5B6067;
}

.bodyCell {
  border: 0.5px solid #5B6067;
  padding: 12px;
  font-size: 18px;
  line-height: 21px;
}

.bodyCell:focus {
  border: 0.5px solid #FFFFFF;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bodyCell img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  visibility: hidden;
  cursor: pointer;
}
.tableBodyRow:hover img {
  visibility: visible;
}

.edit-cell {
  min-width: 386px;
}

.pagination-bar{
  display:flex;
  flex-wrap: wrap;
  margin: 12px 0px;
  align-items: center;
  justify-content: space-between;
}

.page-entries{
  display: flex;
  align-items: center;
  margin-left: auto;
}

.page-entries p,
.page-entries .form-group {
  margin-bottom: 0px;
}

.page-entries p {
  padding-right:5px;
  padding-left:5px;
}

.table thead th {
  position: sticky;
  position: -webkit-sticky;
  background-color: var(--content-background-color);
  top: 0;
  vertical-align: inherit;
  text-align: left;
  z-index: 1;
}

.table thead th:first-child,
.table thead th:nth-child(2),
.table thead th:nth-child(3){
  z-index: 2;
  /* padding-right: 100px; */
  text-align: left;
  /* vertical-align: inherit; */
}

.table thead th:first-child,
.table tbody tr td:nth-child(2),
.table tbody tr td:nth-child(3) {
  position: -webkit-sticky;
  background-color: var(--content-background-color);
  position: sticky;
}

.table thead th:first-child,
.table tbody tr td:nth-child(1){
  left: 0;
}

.table thead tr th:nth-child(2), 
.table tbody tr td:nth-child(2){
  left: 45px;
}

.pagination-button,
.search-button{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.pagination-button:disabled,
.search-button:disabled{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.pagination-button:hover,
.pagination-button:not(:disabled):not(.disabled):active,
.pagination-button:focus,
.search-button:hover,
.search-button:not(:disabled):not(.disabled):active,
.search-button:focus{
  color: #fff;
  background-color: var(--primary-hovor-color);
  border-color: var(--primary-hovor-color);
}

.pagination-button:focus, 
.search-button:focus{
  box-shadow: 0 0 0 0.2rem rgba(120,188,184, 0.5);
}

.search-bar{
  padding: 3px 0px 3px 0px;
  width:334px;
  background: url('../../assets/icons/icon-search-white.svg') right no-repeat;
}

.search-bar input{
  padding-right: 30px;
  margin-right: -15px;
  height: 56px;
  width:300px;
  border-radius: 5px !important;
}

.grade-search-bar{
  padding: 3px 0px;
  width: 170px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search-button{
  font-weight: bolder;
  padding-right:15px;
  padding-left:15px;
}

.noResults{
  color: white;
  font-weight: 100;
  font-size: 20px;
  margin-top: 10px;
}

.table-responsive thead tr td {
  background: var(--content-background-color);
  position: sticky;
  top: 0;
}

.table-responsive tbody tr td:nth-child(1),
.table-responsive thead tr th:nth-child(1)
{
  background: var(--content-background-color);
  left: 0px;
  z-index: 4;
  position: sticky;
}

.table-responsive tbody tr td:nth-child(2),
.table-responsive thead tr th:nth-child(2)
{
  background: var(--content-background-color);
  left: 45px;
  z-index: 4;
  position: sticky;
}

.table-responsive .tableBodyRow:hover > td,
.table-responsive .tableBodyRow:hover{
  background: #2b2b2f !important;
}
.table-responsive .tableBodyRow input[type=checkbox]{
  margin-left: 10px;
  transform: scale(150%);
}

.form-control-wrapper{
 padding-top: 5px;
  width: 350px;
}

.input-label{
  color: var(--sub-text-secondary);

}

.align-empty-cell {
  justify-content: flex-end;
  cursor: pointer;
}

.empty-class-click {
  border: 1px solid #ffffff;
}

.non-empty-cell:hover .expand-linked-cell-wrapper {
  display: flex;
  position: absolute;
  right: 0;
  height: 100%;
  width: 48px;
  background: linear-gradient(270deg, #111113 33.86%, rgba(0, 0, 0, 0) 89.05%);
}

.empty-cell:hover .expand-linked-cell-wrapper {
  display: none;
  position: relative;
}
