.grade-report-chart {
    background: var(--content-background-color);
    color: var(--white);
    width: 100%;
    height: 100%;
    padding: 15px;
}

.grade-report-chart-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.grade-report-dropdowns {
    display: flex;
}

.grade-report-chart-header p {
    font-size: 22px;
    color: white;
    line-height: 26px;
}

.information-card-container {
    margin-bottom: 77px;
    display: flex;
}

.grade-report-chart-header button {
    margin-left: 28px;
}

.grade-report-chart-content {
    margin-bottom: 40px;
    margin-left: 16px;
}

.grade-report-chart-tooltip {
    width: 100% !important;
    border: 1px solid #ccc;
    background-color: #000;
    padding: 10px;
}

.grade-report-chart-tooltip-label {
    font-size: 17px;
    font-weight: 700;
    width: 100% !important;
    color: #d3d3d3;
    margin-bottom: 0px;
    margin-top: 0px;
}

.grade-report-chart-tooltip-value {
    font-size: 17px;
    color: #d3d3d3;
    margin-bottom: 0px;
    margin-top: 0px;
}

.grade-report-color-code {
    display: flex;
    margin-left: 80px;
}

.grade-report-color-code div {
    display: flex;
    align-items: center;
}

.grade-report-color-code-student {
    margin-right: 40px
}

.grade-report-color-code-title {
    color: #d3d3d3;
    font-size: 18px;
    margin-right: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.grade-report-color-code-box {
    height: 20px;
    width: 40px;
}

.grade-report-color-code-box.brand-teal {
    background-color: #77BBB5
}

.grade-report-color-code-box.brand-grey {
    background-color: #808080
}

.grade-report-bottom-bar {
    display: flex;
    margin-top: -70px;
}

p.quiz-exam-text {
    display: flex;
    color: #d3d3d3;
    width: 50%;
    justify-content: center;
    font-size: 22px;
    margin-top: 10.83px;
}

@media screen and (max-width: 700px) {
    .grade-report-bottom-bar {
        flex-direction: column-reverse;
    }
    p.quiz-exam-text {
        align-self: center;
        margin-left: 50px;
    }
}