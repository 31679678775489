.learning-wrapper {
    position: relative;
}

.learning {
    display: grid;
    grid-template-areas: 'scores practice'
                         'edit edit';
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2em;
    margin-bottom: 2em;
}

.learning-section:first-child{
    grid-area: scores;
}

.learning-section:nth-child(2){
    grid-area: practice;
}

.learning-section:nth-child(3){
    grid-area: edit;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.learning-section .title{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #89969F;
}

.section-data-item{
    display: flex;
    flex-direction: row;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 87% */
    letter-spacing: -0.017em;
    color: #FFFFFF;
}

.section-data-quiz{
    margin: 20px 20px 0 0;
    min-width: 40px;
}

.section-data-quiz:nth-child(2){
    font-weight: bold;
}

.section-data-progress{
    margin: 20px 20px 0 0;
    min-width: 150px;
}

.section-data-progress:last-child{
    font-weight: bold;
}

.section-data-quiz__edit, .section-data-quiz__view-submission{
    display: flex;
    align-content: center;
    margin: 20px 10px 0 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #77B1AF;
    cursor: pointer;
}

.section-data-quiz__view-submission > img{
    margin-left: 8px;
}

.section-data-quiz__view{
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #77B1AF;
    cursor: pointer;
}

.change-item {
    display: flex;
    margin-top: 1em;
}

.change-item__date {
    margin-right: 5%;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    width: 130px;
}

.change-item__date > span {
    display: block;
}

.change-item__data {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.017em;
    color: #FFFFFF;
    margin: 12px 0 14px 0;
}

.change-item__data > span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #89969F;
    margin-bottom: 6px;
}

.change-item__data > span:nth-child(1) {
    margin-top: 8px;
    font-weight: bold;
}

.change-item__data > span:nth-child(2) {
    display: flex;
    margin-bottom: 6px !important;
}

.changed__Section{
    line-height: 22px;
}

.note__span{
    width: 60vw;
    margin-bottom: 17px !important;
}

@media screen and (max-width: 700px) {
    .learning {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        height: 100%;
    }

    .learning-section {
        flex: 1;
        margin-bottom: 3em;
    }

    .section-data-quiz__edit{
        margin: 20px 10px 0 0;
    }

    .section-data-quiz:last-child{
        font-weight: bold;
    }

}




