.vip-user-page__container{
    padding: 36px;
}

.vip-user-page__container .confirmation-modal-wrapper{
    height: 210px;
}

.emailcopy-modal-wrapper {
    width: 800px;
    height: auto;
    padding: 1rem;
}

.emailcopy-modal-wrapper > p {
    text-align: center;
}

.emailcopy-modal-wrapper > button {
    background-color: transparent;
    border: 0 none;
    color: #fff;
    text-decoration: underline;
    font-size: 14px;
    margin-bottom: .75rem;
    outline: 0;
}

.emailcopy-modal-wrapper #emailContent {
    font-size: 14px;
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    height: 300px;
    overflow-y: auto;
    margin-bottom: 1rem;
    color: #1c1d21;
}

.vip-user-page__container .form-wrapper .text-field>input:not(.radio),
.vip-user-page__container .form-wrapper .text-field>.react-select__value-container {
    height: 40px;
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
}

.vip-user-page__container .form-wrapper .text-field>input:not(.radio):focus {
    color: #ffffff;
    border-color: gray;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.vip-user-page__container .form-wrapper .text-field>input:not(.radio):not(.date):disabled {
    background: rgba(0, 0, 0, 0.1);
    color: #495057;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.vip-user-page__container .form-wrapper .text-field>input:disabled::placeholder,
.vip-user-page__container .form-wrapper .text-field>input:disabled::-webkit-input-placeholder {
    color: #495057;
}

/* customize label (the container) */
.vip-user-page__container .form-wrapper .text-field>.container {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 12px;
    padding-left: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
    margin-top: 12px;
}

.vip-user-page__container .form-wrapper .text-field.email {
    position: relative;
}

.vip-user-page__container .form-wrapper .text-field.email .fa-spinner {
    position: absolute;
    right: 10px;
    top: 0px;
    color: white;
}
/* hide the browser's default radio button */
.vip-user-page__container .form-wrapper .text-field .container input.checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* create a custom radio button */
.vip-user-page__container .form-wrapper .text-field .container span.check-mark {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    border-radius: 50%;
    border: 2px solid #5FC4B8;
}

/* when the radio button is checked, add a lightgreen background */
.vip-user-page__container .form-wrapper .text-field .container input:checked~.check-mark {
    background: #5FC4B8;
}

/* when the radio button is checked and disabled */
.vip-user-page__container .form-wrapper .text-field .container input:checked:disabled~.check-mark {
    background: #5E636B;
}

/* when the radio button is disabled */
.vip-user-page__container .form-wrapper .text-field .container input:disabled~.check-mark {
    border: 2px solid #5E636B;
}

.vip-user-page__container .field-label {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
}

.vip-user-page__container .text-field {
    margin-bottom: 24px;
}

.vip-user-page__container .text-field.radio {
    margin-bottom: 24px;
}

.vip-user-page__container h3 {
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    margin-top: 12px;
}

.vip-user-page__container .submit-button {
    background: #5FC4B8;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #161618;
    margin-top: 0;
    padding: 16px 24px;
    border: none;
    width: fit-content;
}

.vip-user-page__container .submit-button:disabled {
    background: #558180;
}

.vip-user-page__container .radio-label {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
}

.vip-user-page__container p.error {
    color: #E1774F;
    padding: 0;
    margin-bottom: 9px;
    font-size: 16px;
    line-height: 19px;
}

.vip-user-page__container input.red-border {
    border: 1px solid #E1774F !important;
}
