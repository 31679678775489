.reset-student-progress-wrapper {
    padding: 15px;
}

.reset-student-progress-wrapper .form-wrapper {
    border: 1px solid #e6e5e24f;
    padding: 20px;
}

#student-identifier, .select-button {
    border-radius: 5;
    font-size: var(--input-font-size);
    width: 400px;
}

.select-button {
    color: black;
}

#student-identifier::placeholder {
    color: hsl(0,0%,50%)!important;
}

.error-text {
    text-align: center;
    color: var(--danger);
}

.reset-student-progress-wrapper .btn-primary.disabled:hover {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--white);
}
