.date-section {
    display: flex;
    flex-direction: column;
}

.cohort-container {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0 1em;
    padding-left: 1.8em;
}

.cohort-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px 0 48px 24px;
}

.info-container-margin {
    padding-top: 48px;
    padding-bottom: 60px;
    border-top: 1px solid var(--border-primary);
    border-bottom: 1px solid var(--border-primary);
}

p.course-info-url {
    margin-bottom: 4px;
}

.student-info.cohort-detail {
    display: flex;
    flex-direction: row;
}

.student-info.cohort-detail .timestamp {
    padding-left: 5em;
}

.btn-wrapper {
    display: flex;
    justify-content: space-between !important;
    padding-right: .4em;
}

.btn-wrapper h3{
    cursor: unset;    
}

.btn-wrapper h3 p {
    cursor: pointer;
}

a.course-info-url {
    color: var(--text-primary);
}

.btn-custom.btn-tertiary.detail-btn{
    justify-self: center;
    height: 100%;
    cursor: pointer;
    color: var(--brand-regular);
}

.end-buttons{
    display: flex;
    flex-direction: row;
    width: 18em;
    margin-right: 31px;
}

.btn-enable-disable{
    width: 9em;
}

.btn-enable-disable .fa-spinner{
    margin: auto;
    margin-top: 2px;
}

.btn-enable-disable p{
    margin-left: 10px;
}

svg.svg-inline--fa.fa-toggle-on,
svg.svg-inline--fa.fa-toggle-off {
    font-size: 17px;
}

.btn-wrapper.cohort-back-btn h3 {
    margin: 0 0 15px 4px;
}

.end-buttons-mob  {
    display: none;
}

.color--teal {
    color: var(--brand-regular) !important;
}

@media screen and (max-width: 820px) {
    .cohort-container { 
        padding: 19px 24px 24px 24px;
    }
    .info-container-margin {
        padding: 36px 0;
    }
    .btn-custom.btn-tertiary.detail-btn {
        justify-self: end;
        margin-right: 20px;
    }
    .student_id.cohort {
        margin-left: 0px;
    }
    .cohort-note-wrapper {
        margin-top: 15px;
    }
    .cohort-note-wrapper p:nth-child(1) {
        margin-bottom: 5px;
    }
    .cohort-info {
        display: flex;
        text-align: start;
    }
    .end-buttons {
        flex-direction: row;
        width: 260px;
        height: 58px;
    }
    .btn-enable-disable {
        padding-top: 0px;
    }
    .md-pb-unset  {
        padding-bottom: unset;
    }
    .md-pt-unset  {
        padding-top: unset;
    }
}

@media (max-width: 820px) and (min-width: 480px) {
    .md-pl-40 {
        padding: 0 40px;
    } 
    .cohort-info {
        padding: 24px 0 24px 24px;
    }
    .end-buttons {
        margin-right: 4px;
        align-items: flex-end;
        flex-direction: row;
        width: 260px;
        height: 58px;
        gap: 16px;
    }
    .cohort-container {
        padding-top: unset;
    }
    .info-container-margin {
        padding-bottom: 36px;
    }
}

@media (max-width: 480px) {
    .end-buttons {
        display: none;   
    }
    .end-buttons-mob {
        display: flex;
        flex-direction: column;
    }
    .cohort-info {
        display: flex;
        flex-direction: column;
        text-align: start;
        padding: 0 24px 24px 24px;
    }
    .sm-pt-32 {
        padding-top: 32px;
    }
    .sm-pl-24 {
        padding-left: 24px;
    }
    .sm-mb-12 {
        margin-bottom: 12px;
    }
    .sm-mb-24 {
        margin-bottom: 12px;
    }
 }

 @media (min-width: 820px) {
     .lg-pl-33, .cohort-info {
         padding-left: 33px;
     }

     .cohort-container {
        padding: 0px 31px 0 33px;
     }
 }