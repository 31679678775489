.table thead td {
  min-width: 130px;
  vertical-align: middle;
  text-transform: uppercase;
}

.table tbody tr td {
  font-size: var(--content-font-size);
  font-weight: var(--content-font-weight);
}

.table-wrapper {
  text-align: start;
  font-weight: var(--bold-font-weight);
}

.table {
  border: 1px solid var(--table-border-color);
  color: var(--white);
  display: block;
  border-collapse: separate;
  border-spacing: unset;
  max-height: 70vh;
  overflow-x: auto;
}

.table td {
  padding: 4px;
  border: 1px solid #585859;
}

.table thead tr td {
  background: var(--content-background-color);
  position: sticky;
  top: 0;
}

.table tbody tr td:nth-child(1) {
  background: var(--content-background-color);
  left: 0;
  min-width: 45px;
  position: sticky;
  word-break: break-all;
  text-align: center;
  cursor: pointer;
}

.table tbody tr td:nth-child(2) {
  background: var(--content-background-color);
  left: 45px;
  min-width: 300px;
  position: sticky;
  word-break: break-all;
}

.table tbody tr td:nth-child(3) {
  background: var(--content-background-color);
  left: 345px;
  position: sticky;
}
