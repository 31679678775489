:root {
  /*remove the css variable after updating with new variables*/
  --primary-color: #78bcb8;
  --primary-hovor-color: #5eafab;
  --placeholder-color: #D9D9D9;
  --link-color: #61dafb;
  --navbar-background-color: #0f0f10;
  --information-card-button-color: #77B1AF;
  --form-error-color: #E1774F;
  --form-input-border-color:#616162;
  --dropdown-menu-background-color: #5F5F5F;
  --dropdown-item-background-color: #525252;
  --sidebar-background-color: #1e1e1e;
  --content-background-color: #161618;
  --modal-content-background-color: #3a3838;
  --information-card-background-color: #1D1D1F;
  --student-status-edit-modal-background-color: #25272B;
  --table-border-color: #dee2e6;
/*new css variable*/
  --brand-regular: #5FC4B8;
  --brand-regular-hover: #B8FFF7;
  --brand-regular-active: #B8FFF74D;
  --brand-regular-disabled: #5FC4B84D;
  --brand-light: #C7F6F0;
  --brand-medium: #33A19B;
  --brand-dark: #0A2A2D;
  --brand-dark-hover: #0F3E43;
  --brand-dark-active: #0F3E434D;
  --brand-dark-disabled: #0A2A2D4D;

  --text-primary: #FFFFFF;
  --text-primary-off: #D6E7E7;
  --text-secondary: #B1BFC5;
  --text-tertiary: #C9D4DE;
  --text-dark: #161618;
  --text-dark-off: #98A4AE;
  --sub-text-primary: #C1CFD9;
  --sub-text-secondary: #CCD4D8;

  --canvas-dark: #000000;
  --canvas-dark-off: #2C2E32;
  --canvas-primary: #161618;
  --canvas-secondary: #1D1D1F;
  --canvas-tertiary: #101011;

  --black-primary: #121212;
  --black-secondary: #1D1D1F;
  --black-tertiary: #0D0D0F;
  --black-quaternary: #0f0f11;

  --gray-primary: #25272B;
  --gray-secondary: #33373E;
  --gray-tertiary: #262629;

  --input-primary: #0000004D;
  --shadow-primary: #0000007B;

  --border-primary: #FFFFFF4D;
  --border-secondary: #5B6067;
  --border-tertiary: #606060;

  --warning-bg: #342215;
  --warning-color: #FFBB55;
  --error-bg: #2E141C;
  --error-color: #E1774F;
  --info-bg: #191631;
  --info-color: #969BFF;

  --link-font-size: 0.81rem;
  --input-font-size: 0.875rem;
  --content-font-size: 1.125rem;
  --header-font-size: 1.5rem;
  --content-font-weight: 300;
  --link-font-weight: 400;
  --bold-font-weight: bold;
}

body {
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
  color: var(--text-primary);
  background-color: var(--brand-regular);
  border-color: var(--brand-regular);
}

.btn-primary:hover {
  color: var(--text-primary);
  background-color: var(--brand-regular-hover);
  border-color: var(--brand-regular-hover);
}

.modal button {
  border-radius: 0;
}

/* Common styling for dropdown menu */
.dropdown .dropdown-menu {
  background-color: var(--gray-primary);
}

.dropdown .dropdown-item {
  color: var(--white);
}

.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover {
  background-color: var(--gray-secondary);
  outline: 0;
}

.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover {
  background-color: var(--gray-secondary);
  outline: 0;
}

/* Common styling for modal */
.modal .modal-header .close {
  color: var(--white);
}

.modal .modal-content {
  background: var(--gray-primary);
  color: var(--white);
}

.dropdown .dropdown-toggle {
  min-width: 160px;
  text-align: left;
}

/* Common styling for table */
.table-wrapper  #formGroupPagination {
  margin: 0 4px;
}

.table-wrapper .controlRow__root {
  margin: 15px 0;
}

.Toastify__toast-container {
  width: 300px !important;
  --toastify-font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.toastContainer {
  background: var(--gray-primary) !important;
  box-shadow: 0px 4px 20px var(--shadow-primary) !important;
  border-radius: 5px;
  padding: 24px !important;
  color: var(--text-primary) !important;
  cursor: revert !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__toast-body > div {
  width: 100%;
}

.toastContainer .Toastify__close-button {
  color: var(--text-primary);
  opacity: 1;
}
