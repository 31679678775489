.table-grid > span {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #89969F;
    border-bottom: 1px solid #FFFFFF;
    padding: 20px 0;
}

.table, table tbody.tableBody, .table .bodyCell, .table thead th {
    border-color: #585859;
}

.table thead th {
    border-width: 1px;
}

.expanded {
    background: #1D1D1F;
    border-bottom: none !important;
    border-left: 3px solid #77B1AF;
}

@media screen and (max-width: 700px) {
    .table-grid {
        display: none;
    }
}
