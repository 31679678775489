.voucher-code-page__container .react-datepicker-wrapper,
.voucher-code-page__container .react-datepicker-wrapper input {
    width: 100%
}

.voucher-code-page__container .react-datepicker-wrapper input {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: .25rem;
    color: #495057
}

.voucher-code-page__container .react-datepicker__input-container::after {
    content: url('../../assets/icons/calendar.svg');
    position: absolute;
    right: 5%;
    top: 22%;
    cursor: pointer
  }

.form-wrapper .error {
    padding: 5px 10px 10px 0
}

.form-wrapper .text-success {
    color: #fff !important
}

.form-wrapper #message-container {
    display: flex
}

.form-wrapper #message-container div,
.form-wrapper #message-container div div {
    display: flex;
    color: #fff;
    align-items: center
}

.form-wrapper #message-container div {
    background-color: #2f4f4f;
    padding: 10px 20px;
    margin-bottom: 1em;
    border-radius: 5px    
}

.form-wrapper #message-container div div {
    padding: 0;
    margin: 0;
}

.form-wrapper #message-container #message {
    margin-bottom: 0;
    padding: 5px 10px;
    border: 1px solid;
    border-radius: 5px;
    margin: 0 10px
}

.form-wrapper #message-container .fa-copy {
    cursor: pointer;
}
