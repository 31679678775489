.no-access {
  height: 100vh;
  background: var(--content-background-color);
  color: var(--white);
}

.no-access span {
  font-size: 18px;
}

.no-access-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}