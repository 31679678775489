.grade-detail {
    height: 100vh;
}

.admin-home {
    display: flex;
    width: 12em;
    padding-top: 1em;
    padding-left: 2em;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #77B1AF;
}

.edit-email-button { 
    margin-left: 24px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--information-card-button-color);
    cursor: pointer;
}

.admin-home p {
    margin-left: 10px;
}

.heading-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2em .9em 0 2em;
    margin-bottom: 16px;
}

.student-name {
    font-size: 2rem;
    font-weight: 300 !important;
}

.student-active {
    font-size: 18px;
    line-height: 22px;
    color: #89969F;
    align-self: flex-end;
}

.student-container {
    display: flex;
    width: 100%;
    height: auto;
    padding: 1em;
    padding-left: 1.8em;
}

.student-info-constainer {
    display: flex;
    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
    border-bottom: 2px solid #202124;
    border-top: 2px solid #202124;
}

.student-info, .note {
    display: flex;
    flex: 1;
}

.student-info {
    flex-direction: column;
}

.student-info__item{
    display: flex;
}

.student-info span {
    line-height: 22px;
    font-size: 18px;
    margin-bottom: 12px;
}

.preferred_name, .email_address {
    margin-left: 31.38px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    color: #FFFFFF;
}

.student_id {
    margin-left: 72px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    color: #FFFFFF;
}

.email_address {
    color: #77B1AF;
    margin-left: 47.38px;
}

.note {
    flex-direction: column;
}

.note p {
    line-height: 1.2em;
    font-family: Lato;
}

.chart-container {
    padding: 1em;
}

@media screen and (max-width: 700px) {
    .student-info-constainer {
        flex-direction: column;
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .note {
        padding-left: 0px;
    }
    .student-container {
        padding-left: 1em;
    }
    .student-info p:nth-child(1) {
        margin-left: 1em !important;
    }
    .heading-info {
        display: flex;
        flex-direction: column;
        text-align: start;
    }
    .student-active {
        align-self: flex-start;
    }
    .student-info__item {
        display: flex;
        flex-direction: column;
    }
    .preferred_name, .email_address {
        margin-left: 0px;
    }
    .student-info span {
        margin-bottom: 0px;
    }

}
