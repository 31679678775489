.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: var(--link-color);
}

@font-face{
  font-family: Lato;
  src: url('./assets/fonts/Lato-Regular.ttf');
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Lato;
  src: url(assets/fonts/Lato-Regular.ttf);
}
